import React from 'react'
import Badges from './Badges'

const BadgeListing = ({ data }) => {
    // console.log(slug)
    return (
        <div>
            <div className="container">
                <div className="row my-5 align-items-center">
                    {
                        data.allContentfulBadges.edges.map((edge) => {
                            return (
                              <div className="col center" key={edge.node.orderId}>
                                  <Badges src={edge.node.badge.fluid.src} link={edge.node.badgeLink}/>
                              </div>
                        )
                        })

                    }
                </div>
            </div>
        </div>
    )
}
export default BadgeListing
