import React from 'react'

const Badges = ({ src, link }) => {
    return (
        <a href={link} target="_blank" rel="noopener noreferrer">
            <img
            className="LogoStyle"
            src={src}
            alt="badge"
        />
        </a>
    )
}
export default Badges
