import React  from "react"
import styled from "styled-components"
import Content from "../components/Content"
import Heading from '../components/elements/Heading'
import './aboutus.css'
import Layout from "../components/Layout"
import BadgeListing from "../components/Badges/BadgeListing"
import { graphql, useStaticQuery } from "gatsby"


const AboutUs = () => {
  const data = useStaticQuery(graphql`
  query {
    contentfulBannerImages (path: {eq: "/AboutUs"}) {
      image {
        fluid(quality:100) {
          src
        }
      }
      heading
    }
    aboutUsBlock: contentfulPageTextAndHeadings(path: {eq: "/AboutUs"}) {
      mainHeading1
      text {
        childContentfulRichText {
          html
        }
      }
    }
    allContentfulBadges(filter: {slug: {eq: "AboutUs"}}, sort: {fields: orderId}) {
        edges {
          node {
            orderId
            badge {
              fluid(quality: 100) {
                src
              }
            }
            badgeLink
          }
        }
      }
    }
  `)
  const ImgDiv = styled.div`
  background-image: url(${data.contentfulBannerImages.image.fluid.src});
  height: 300px;
  color: #ffffff;
  object-fit: cover;
  background-position: center;
  background-size: cover;
  background-repeat: no-repeat;
  display:flex;
  justify-content: center;

`
    return (
      <Layout>
       

          <ImgDiv >
            <h3 className="center"
              style={{
                fontFamily: "Playfair Display",
                fontSize: 50,
                fontWeight: "bold",
                textAlign: "center",
                margin: "auto",
              }}
            >
              {data.contentfulBannerImages.heading}
            </h3>
          </ImgDiv>
          <div className="container mt-5 mb-5" style={{ textAlign: "-webkit-center" }}>
            <Heading className="headingAbout"><Content heading={data.aboutUsBlock.mainHeading1} /> </Heading>
          </div>
          <div className="container mt-md-5 mt-xs-2 mt-sm-3 mt-lg-5">
            <div className="text-justify">
              <p dangerouslySetInnerHTML={{ __html: data.aboutUsBlock.text.childContentfulRichText.html }}/>
            </div>
          </div>

          <BadgeListing data={data} />
        
      </Layout>
    )
}
export default AboutUs
